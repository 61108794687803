import { toRaw, ref, nextTick, unref } from 'vue'
import type { RouteLocationNormalized } from 'vue-router'

import { useSortable } from 'framework/hooks/web/useSortable'
import { useMultipleTabStore } from '@admin/store/modules/multipleTab'
import { isNullAndUnDef } from 'framework/utils/is'
import projectSetting from '@admin/settings/projectSetting'
import { useRouter } from 'vue-router'
import { REDIRECT_NAME } from '@admin/router/constant'
import { useUserStore } from '@admin/store/modules/user'
// import { router } from '@admin/router'
import { useSystemMenuStore } from '@admin/store/modules/systemMenu'
import { findMenuParentIds } from '@admin/utils/systemMenu/index.ts'
import dayjs from 'dayjs'

export function initAffixTabs(): string[] {
  const affixList = ref<RouteLocationNormalized[]>([])
  const tabStore = useMultipleTabStore()
  const router = useRouter()
  /**
   * @description: Filter all fixed routes
   */
  function filterAffixTabs(routes: RouteLocationNormalized[]) {
    const tabs: RouteLocationNormalized[] = []
    routes &&
      routes.forEach((route) => {
        if (route.meta && route.meta.affix) {
          tabs.push(toRaw(route))
        }
      })
    return tabs
  }

  /**
   * @description: Set fixed tabs
   */
  function addAffixTabs(): void {
    const affixTabs = filterAffixTabs(router.getRoutes() as unknown as RouteLocationNormalized[])
    affixList.value = affixTabs
    for (const tab of affixTabs) {
      tabStore.addTab({
        meta: tab.meta,
        name: tab.name,
        path: tab.path,
      } as unknown as RouteLocationNormalized)
    }
  }

  let isAddAffix = false

  if (!isAddAffix) {
    addAffixTabs()
    isAddAffix = true
    tabStore.tabTopping()
    tabStore.setAffixEndIndex(affixList.value.length)
  }
  return affixList.value.map((item) => item.meta?.title).filter(Boolean) as string[]
}

export function useTabsDrag(affixTextList: string[]) {
  const tabStore = useMultipleTabStore()
  const { multiTabsSetting } = projectSetting
  nextTick(() => {
    if (!multiTabsSetting.canDrag) return
    const el = document.querySelectorAll(`.ant-tabs-nav-wrap > div`)?.[0] as HTMLElement
    const { initSortable } = useSortable(el, {
      filter: (e: ChangeEvent) => {
        const text = e?.target?.innerText
        if (!text) return false
        return affixTextList.includes(text)
      },
      onEnd: (evt) => {
        const { oldIndex, newIndex } = evt

        if (isNullAndUnDef(oldIndex) || isNullAndUnDef(newIndex) || oldIndex === newIndex) {
          return
        }

        tabStore.sortTabs(oldIndex, newIndex)
      },
    })
    initSortable()
  })
}

export const useAddTab = (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const systemMenuStore = useSystemMenuStore()
  const { name, query } = to
  to.meta.openTime = dayjs().unix()
  // iframe 需要修改mate.title、menuId
  if (name === 'iframe') {
    console.log('to----------------a-sd-as-da-sda', to)
    const menu = systemMenuStore.allMenuList.find((v) => `${v.id}` === `${query.menuId}`)
    to.meta.title = menu?.title || 'iframe页面'
    to.meta.menuId = menu?.id
    to.meta.systemId = systemMenuStore?.curSystem
    if (!to.meta.iframeId) {
      to.meta.iframeId = `iframe-${dayjs().valueOf()}`
    }
    // iframe情况下，认菜单配置的缓存项
    if (menu && menu.iframe_cache === 0) {
      to.meta.cache = true
    }
    // 链接上携带cache参数的
    if (query.cache) {
      to.meta.cache = true
    }
  }
  if (!to.meta.systemId) {
    // 先检查to 在menu中是否存在，存在代表有系统信息，不存在从from中取
    const inspectTo = systemMenuStore.allMenuList.find((v) => v.path === to.path)
    if (inspectTo) {
      // to中有系统信息
      const menuParentIds = findMenuParentIds(inspectTo)
      menuParentIds && menuParentIds.length && (to.meta.systemId = menuParentIds[0])
    } else {
      // 1、打开详情页-会进来
      const inspectFrom = systemMenuStore.allMenuList.find((v) => v.path === from.path)
      if (inspectFrom) {
        const menuParentIds = findMenuParentIds(inspectFrom)
        if (menuParentIds && menuParentIds.length) {
          to.meta.systemId = menuParentIds[0]
          to.meta.menuId = menuParentIds[menuParentIds.length - 1]
        }
      }
    }
  }
  const userStore = useUserStore()
  const tabStore = useMultipleTabStore()
  if (['PAGE_NOT_FOUND_NAME', REDIRECT_NAME].includes(name as any) || !to || !userStore.token) {
    return
  }
  tabStore.addTab(unref(to))
  tabStore.addTabCache(unref(to))
}
